import PageTextPage from 'themes/base/components/content/PageTextPage'

export default {
  extends: PageTextPage,
  computed: {
    aboutMenu() {
      if (
        this.page &&
        this.page.custom_menu_code &&
        this.page.custom_menu_code.length > 0 &&
        this.$store.state.menu[this.page.custom_menu_code]
      ) {
        return this.$store.state.menu[this.page.custom_menu_code]
      } else {
        return this.$store.state.menu.about_purchase || {}
      }
    },
  },
}
