import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import ContactBox from '@theme/components/shop/ContactBox'
import ContentBuilder from '@theme/components/utils/ContentBuilder'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import UniqueSellingPoints from '@theme/components/shop/UniqueSellingPoints'
import VerticalMenu from '@theme/components/shop/VerticalMenu'

export default {
  mixins: [ImageUrlMixin, SeoMixin],
  components: {
    AppTitle,
    Breadcrumbs,
    ContactBox,
    ContentBuilder,
    LayoutBase,
    ProductsVisited,
    UniqueSellingPoints,
    VerticalMenu,
  },
  props: {
    page: Object,
  },
  data() {
    return {
      CLASS_CONTACT_BOX: 'd-none d-md-block',
      CLASS_CONTENT_ROW: [
        'row align-content-lg-stretch',
        {
          'flex-row-reverse':
            this.$themeSettings.components.PageTextPage.sidebar.enable &&
            this.$themeSettings.components.PageTextPage.sidebar.position === 'left',
          'justify-content-md-center':
            !this.$themeSettings.components.PageTextPage.sidebar.enable &&
            this.$themeSettings.components.PageTextPage.centerContent,
        },
      ],
      CLASS_CONTENT_COLUMN: [
        'col-24 mb-3 mb-lg-5',
        {
          'col-lg-18 col-xl-17': this.$themeSettings.components.PageTextPage.sidebar.enable,
          'offset-xl-1':
            this.$themeSettings.components.PageTextPage.sidebar.enable &&
            this.$themeSettings.components.PageTextPage.sidebar.position === 'left',
          'col-md-auto':
            !this.$themeSettings.components.PageTextPage.sidebar.enable &&
            this.$themeSettings.components.PageTextPage.centerContent,
        },
      ],
      CLASS_SIDEBAR_COLUMN: [
        'col-24 col-lg-6 mb-3 mb-lg-5 d-print-none',
        {
          'offset-xl-1': this.$themeSettings.components.PageTextPage.sidebar.position === 'right',
        },
      ],
      contactBox: {},
    }
  },
  head() {
    return this.getSeoHead()
  },
  created() {
    if (
      this.page.contact_box_reference &&
      this.page.contact_box_reference[0] &&
      this.page.contact_box_reference[0].entity
    ) {
      const contactBox = this.page.contact_box_reference[0].entity
      this.contactBox.description = contactBox.description
      this.contactBox.showButton = !!contactBox.show_button
      this.contactBox.title = contactBox.title_frontend
    } else {
      this.contactBox.showButton = this.$themeSettings.components.PageTextPage.contactBox.showButton
    }
  },
  computed: {
    breadcrumbs() {
      const breadcrumbs = []
      if (this.$contentSettings.aboutPurchase.enable) {
        breadcrumbs.push({
          path: this.localePath({ name: 'aboutPurchase' }),
          title: this.$t('PageTextPage.breadcrumbs.base'),
        })
      }
      breadcrumbs.push({
        path: this.$route.path,
        title: this.page.title,
      })
      return breadcrumbs
    },
  },
}
